import {Link} from "react-router-dom";
import brand from "../img/Logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Footer() {
    return (
        <>
            <div className="footer-area black-bg sec-p-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="footer-widget widget-1">
                                <div className="footer-logo">
                                    <Link to="">
                                        <img src={brand} alt="footer-logo" className="img-fluid"/>
                                    </Link>
                                </div>

                                <p>
                                    Alloys Top Trading is an international metal trading company
                                    based in Dubai since 2023. We specialize in trading a diverse
                                    range of high-quality metals, including noble alloys, minor
                                    metals and scrap.
                                    We source metals from partner producers and supply to
                                    end-users.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="footer-widget widget-2">
                                <h3>popular link</h3>
                                <ul className="footer-popu-link">
                                    <li>
                                        <Link to="">
                                            ferro-alloys<i
                                            className="fa fa-arrow-circle-o-right"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            industry<i className="fa fa-arrow-circle-o-right"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            materials<i className="fa fa-arrow-circle-o-right"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            metallurgy<i
                                            className="fa fa-arrow-circle-o-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="footer-widget widget-3">
                                <h3>contact info</h3>
                                <div className="office-address border-bottom mb-20 pb-20">
                                    <p> Office M086, W2 Building, DAFZA, DUBAI, UAE</p>
                                </div>
                                <ul className="footer-social">
                                    <li>
                                        <FontAwesomeIcon icon={["fas", "phone"]} size="1x"/>
                                        <span className="ml-2">+971 54 781 5535</span>
                                    </li>
                                    <li>
                                        <Link className="bg-color5"
                                              to="mailto:sales@alloystrading.ae">
                                            <FontAwesomeIcon icon={["fas", "envelope"]} size="1x"/>
                                            <span className="ml-2">  sales@alloystrading.ae</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copy-right text-center">
                                copyright <Link to=""> Alloys Top Trading FZCO 2024 </Link>
                                | all rights reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
