import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useState} from "react";
import brand from "../img/Logo.png";

function Header() {

    const [openedDrawer, setOpenedDrawer] = useState(false)

    function toggleDrawer() {
        setOpenedDrawer(!openedDrawer);
    }

    function changeNav(event) {
        if (openedDrawer) {
            setOpenedDrawer(false)
        }
    }

    return (
        <header>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" onClick={changeNav}>
                        <img src={brand} alt="header-logo" className="img-fluid"/>
                        {/*<span className="ms-2 h5">ALLOYS TOP TRADING</span>*/}
                    </Link>
                    <div className="main-menu-area">
                        <div className="container">
                            <div className="main-menu-border bg-color3">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="tractour-main-menu-area">
                                            <nav
                                                className="navbar navbar-expand-xl navbar-light main-menu">
                                                <button
                                                    className="navbar-toggler"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target="#navbarSupportedContent"
                                                    aria-controls="navbarSupportedContent"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                >
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>

                                                <div
                                                    className="collapse navbar-collapse"
                                                    id="navbarSupportedContent"
                                                >
                                                    <ul className="navbar-nav mr-auto ml-auto">
                                                        <li className="nav-item active">
                                                            <Link
                                                                className="nav-link"
                                                                to={"/"}
                                                            >
                                                                Home
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item active">
                                                            <Link
                                                                className="nav-link"
                                                                to={"/products"}
                                                            >
                                                                Product
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={"navbar-collapse offcanvas-collapse " + (openedDrawer ? 'open' : '')}>
                        <ul className="navbar-nav me-auto mb-sm-auto" />
                        <span>E-mail: sales@alloystrading.ae   Tel: +971547815535 </span>
                        <button type="button"
                                className="btn btn-outline-dark me-3 d-none d-lg-inline">
                            <FontAwesomeIcon icon={["fas", "shopping-cart"]}/>
                            <span className="ms-3 badge rounded-pill bg-dark">0</span>
                        </button>
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <a
                                    href="!#"
                                    className="nav-link dropdown-toggle"
                                    data-toggle="dropdown"
                                    id="userDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <FontAwesomeIcon icon={["fas", "user-alt"]}/>
                                </a>
                                <ul
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="userDropdown"
                                >
                                    <li>
                                        <Link to="/" className="dropdown-item" onClick={changeNav}>
                                            Login
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="dropdown-item" onClick={changeNav}>
                                            Sign Up
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="d-inline-block d-lg-none">
                        <button type="button" className="btn btn-outline-dark">
                            <FontAwesomeIcon icon={["fas", "shopping-cart"]}/>
                            <span className="ms-3 badge rounded-pill bg-dark">0</span>
                        </button>
                        <button className="navbar-toggler p-0 border-0 ms-3" type="button"
                                onClick={toggleDrawer}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
