import {Link} from "react-router-dom";
import Product from "./Product";
import ProductH from "./ProductH";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import FTuImage from "../img/FTu.webp";
import FNbImage from "../img/FeNb.webp";
import FeTiImage from "../img/FeTi.jpg";
import TiSpongeImage from "../img/TiSponge.jpg";
import FeVanImage from "../img/FeVan.jpg";
import FeMoImage from "../img/FeMo.jpg";

const categories = [
    "All Products",
    "Ferro Alloys",
];

const products = [
    {name: "Ferro Tungsten", image: FTuImage, price: 184},
    {name: "Ferro Niobium", image: FNbImage, price: 191},
    {name: "Ferro Titanium", image: FeTiImage, price: 84},
    {name: "Titanium Sponge", image: TiSpongeImage, price: 91},
    {name: "Ferro Vanadium", image: FeVanImage, price: 161},
    {name: "Ferro Molybdenum", image: FeMoImage, price: 174},
];

const brands = ["Apple", "Samsung", "Google", "HTC"];

const manufacturers = ["HOCO", "Nillkin", "Remax", "Baseus"];

function FilterMenuLeft() {
    return (
        <ul className="list-group list-group-flush rounded">
            <li className="list-group-item d-none d-lg-block">
                <h5 className="mt-1 mb-2">Browse</h5>
                <div className="d-flex flex-wrap my-2">
                    {categories.map((v, i) => {
                        return (
                            <Link
                                key={i}
                                to="/products"
                                className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
                                replace
                            >
                                {v}
                            </Link>
                        );
                    })}
                </div>
            </li>
            {/*<li className="list-group-item">*/}
            {/*  <h5 className="mt-1 mb-1">Brands</h5>*/}
            {/*<div className="d-flex flex-column">*/}
            {/*  {brands.map((v, i) => {*/}
            {/*    return (*/}
            {/*      <div key={i} className="form-check">*/}
            {/*        <input className="form-check-input" type="checkbox" />*/}
            {/*        <label className="form-check-label" htmlFor="flexCheckDefault">*/}
            {/*          {v}*/}
            {/*        </label>*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</div>*/}
            {/*</li>*/}
            {/*<li className="list-group-item">*/}
            {/*  <h5 className="mt-1 mb-1">Manufacturers</h5>*/}
            {/*  <div className="d-flex flex-column">*/}
            {/*    {manufacturers.map((v, i) => {*/}
            {/*      return (*/}
            {/*        <div key={i} className="form-check">*/}
            {/*          <input className="form-check-input" type="checkbox" />*/}
            {/*          <label className="form-check-label" htmlFor="flexCheckDefault">*/}
            {/*            {v}*/}
            {/*          </label>*/}
            {/*        </div>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </div>*/}
            {/*</li>*/}
            <li className="list-group-item">
                <h5 className="mt-1 mb-2">Price Range</h5>
                <div className="d-grid d-block mb-3">
                    <div className="form-floating mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Min"
                            defaultValue="1000"
                        />
                        <label htmlFor="floatingInput">Min Price</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Max"
                            defaultValue="500000"
                        />
                        <label htmlFor="floatingInput">Max Price</label>
                    </div>
                    <button className="btn btn-dark">Apply</button>
                </div>
            </li>
        </ul>
    );
}

function ProductList() {
    const [viewType, setViewType] = useState({grid: true});

    function changeViewType() {
        setViewType({
            grid: !viewType.grid,
        });
    }

    return (
        <div className="container mt-5 py-4 px-xl-5">
            <ScrollToTopOnMount/>
            <nav aria-label="breadcrumb" className="bg-custom-light rounded">
                <ol className="breadcrumb p-3 mb-0">
                    <li className="breadcrumb-item">
                        <Link
                            className="text-decoration-none link-secondary"
                            to="/products"
                            replace
                        >
                            All Products
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Ferro Alloys
                    </li>
                </ol>
            </nav>

            <div className="h-scroller d-block d-lg-none">
                <nav className="nav h-underline">
                    {categories.map((v, i) => {
                        return (
                            <div key={i} className="h-link me-2">
                                <Link
                                    to="/products"
                                    className="btn btn-sm btn-outline-dark rounded-pill"
                                    replace
                                >
                                    {v}
                                </Link>
                            </div>
                        );
                    })}
                </nav>
            </div>

            <div className="row mb-3 d-block d-lg-none">
                <div className="col-12">
                    <div id="accordionFilter" className="accordion shadow-sm">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button fw-bold collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFilter"
                                    aria-expanded="false"
                                    aria-controls="collapseFilter"
                                >
                                    Filter Products
                                </button>
                            </h2>
                        </div>
                        <div
                            id="collapseFilter"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFilter"
                        >
                            <div className="accordion-body p-0">
                                <FilterMenuLeft/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4 mt-lg-3">
                <div className="d-none d-lg-block col-lg-3">
                    <div className="border rounded shadow-sm">
                        <FilterMenuLeft/>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="d-flex flex-column h-100">
                        <div className="row mb-3">
                            <div className="col-lg-3 d-none d-lg-block">
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    defaultValue=""
                                >
                                    <option value="">All alloys</option>
                                    <option value="1">Ferro Niobium</option>
                                    <option value="2">Ferro Titanium</option>
                                    <option value="3">Titanium Sponge</option>
                                    <option value="4">Ferro Nickel</option>
                                    <option value="5">Ferro Molybdenum</option>
                                    <option value="6">Ferro Tungsten</option>

                                </select>
                            </div>
                            <div className="col-lg-9 col-xl-5 offset-xl-4 d-flex flex-row">
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search products..."
                                        aria-label="search input"
                                    />
                                    <button className="btn btn-outline-dark">
                                        <FontAwesomeIcon icon={["fas", "search"]}/>
                                    </button>
                                </div>
                                <button
                                    className="btn btn-outline-dark ms-2 d-none d-lg-inline"
                                    onClick={changeViewType}
                                >
                                    <FontAwesomeIcon
                                        icon={["fas", viewType.grid ? "th-list" : "th-large"]}
                                    />
                                </button>
                            </div>
                        </div>
                        <div
                            className={
                                "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 " +
                                (viewType.grid ? "row-cols-xl-3" : "row-cols-xl-2")
                            }
                        >
                            {Array.from({length: 6}, (_, i) => {
                                if (viewType.grid) {
                                    return (
                                        <Product key={i} percentOff={i % 2 === 0 ? 15 : null}
                                              name={products[i].name}
                                              price={products[i].price}
                                              image={products[i].image}
                                        />
                                    );
                                }
                                return (
                                    <ProductH key={i} percentOff={i % 4 === 0 ? 15 : null}
                                              name={products[i].name}
                                              price={products[i].price}
                                              image={products[i].image}
                                    />
                                );
                            })}
                        </div>
                        <div className="d-flex align-items-center mt-auto">
              <span className="text-muted small d-none d-md-inline">
                Showing 6 of 6
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductList;
